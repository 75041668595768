import { Dispatch } from 'redux';
import url from 'url';
import qs from 'querystring';
import Router from 'next/router';
import cookie from 'js-cookie';

import { TOKEN_EXPIRATION_DAYS } from '../lib/constants';
import * as types from './actionTypes';
import track from '../lib/track';
import { localStore } from '../lib/storage';
import { fetchSearchSurvey } from './searchSurvey';
import { fetchSignupSurvey, saveSurveyValues } from './signupSurvey';
import { getOrCreateStore } from '../lib/with-redux-store';
import { updateProfile } from './auth';
import moment from 'moment';
import { disableQuickStartGuide, sendVerification } from './dashboard';
import EventEmitter from '../lib/eventEmitter';
import { togglePhoneNumberOauthModal } from './dashboard';

export interface ConfigTypes {
  authorizationUrl: string;
  clientId: string;
  height: number;
  postAuthPath?: string;
  redirectUri: string;
  scope: string;
  signupPath?: string;
  entrancePath?: string;
  url: string;
  utmCampaign?: string;
  utmMedium?: string;
  utmSource?: string;
  utmTerm?: string;
  width: number;
  storeSurvey?: boolean;
}

const apiPort = '3000';
const webPort = '3001';

// Check for the window object, since it won't exist on the server
let apiHost = 'https://api.toriihomes.com';
if (typeof window !== 'undefined') {
  if (window.location.hostname === 'localhost') {
    apiHost = `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${apiPort}` : ''
    }`;
  }
}

const faceBookAuthorizationUrl = 'https://www.facebook.com/v10.0/dialog/oauth';
const googleAuthorizationUrl = 'https://accounts.google.com/o/oauth2/auth';

let host = 'https://www.toriihomes.com';
if (typeof window !== 'undefined') {
  if (
    window.location.hostname === 'localhost' ||
    window.location.hostname === 'torii-staging.toriihomes.com'
  ) {
    host = `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${webPort}` : ''
    }`;
    // TODO 9/18/20: default to this once we've fully cut over to the new domain
  } else if (window.location.hostname === 'www.toriihomes.com') {
    host = 'https://www.toriihomes.com';
  }
}

/**
 * Wrapper for setting up oauth
 * @param {Object} config
 * @param {any} dispatch
 * @param {boolean} isSignup - True if the user is signing<br>
 * up for a new account. False if they are logging in.
 */
function oauth2(
  config: ConfigTypes,
  dispatch: Dispatch<any>,
  isSignup: boolean,
) {
  return new Promise(resolve => {
    const params = {
      client_id: config.clientId,
      display: 'popup',
      redirect_uri: config.redirectUri,
      response_type: 'code',
      scope: config.scope,
    };
    const URL: string = `${config.authorizationUrl}?${qs.stringify(params)}`;
    resolve({ URL, config, dispatch, isSignup });
  });
}

/**
 * Open an oauth popup
 * @param {string} URL
 * @param {Object} config
 * @param {any} dispatch
 * @param {boolean} isSignup - True if the user is signing<br>
 * up for a new account. False if they are logging in.
 */
function openPopup({ URL, config, dispatch, isSignup }: any) {
  return new Promise(resolve => {
    const width = config.width;
    const height = config.height;
    const options = {
      height,
      left: window.screenX + (window.outerWidth - width) / 2,
      top: window.screenY + (window.outerHeight - height) / 2.5,
      width,
    };
    const popup: any = window.open(URL, '_blank', qs.stringify(options, ','));

    // Timer to check if the window is closed by user
    const timer = setInterval(function() {
      if (popup.closed) {
        clearInterval(timer);
        EventEmitter.emit('signupError');
      }
    }, 1000);

    if (URL === 'about:blank') {
      popup.document.body.innerHTML = 'Loading...';
    }

    resolve({
      config,
      dispatch,
      isSignup,
      window: popup,
    });
  });
}

/**
 * Close the oauth popup
 * @param {Object} window
 * @param {Number} interval
 */
function closePopup({ window, interval }: any) {
  return new Promise(resolve => {
    clearInterval(interval);
    window.close();
    resolve();
  });
}

/**
 * Check the oauth popup for a response from an oauth provider
 * @param {Object} window
 * @param {Object} config
 * @param {any} requestToken - A request token from the oauth provider
 * @param {any} dispatch
 * @param {boolean} isSignup - True if the user is signing<br>
 * up for a new account. False if they are logging in.
 */
function pollPopup({ window, config, requestToken, dispatch, isSignup }: any) {
  return new Promise(resolve => {
    const redirectUri: any = url.parse(config.redirectUri);
    const redirectUriPath: string = redirectUri.host + redirectUri.pathname;

    if (requestToken) {
      window.location = `${config.authorizationUrl}?${qs.stringify(
        requestToken,
      )}`;
    }

    const polling = setInterval(() => {
      if (!window || window.closed) {
        clearInterval(polling);
      }
      try {
        const popupUrlPath = window.location.host + window.location.pathname;
        if (popupUrlPath === redirectUriPath) {
          if (window.location.search || window.location.hash) {
            const query = qs.parse(
              window.location.search.substring(1).replace(/\/$/, ''),
            );
            const hash = qs.parse(
              window.location.hash.substring(1).replace(/[/$]/, ''),
            );
            const params = Object.assign({}, query, hash);

            if (params.error) {
              dispatch({
                messages: [{ msg: params.error }],
                type: types.OauthFailure,
              });
            } else {
              resolve({
                config,
                dispatch,
                interval: polling,
                isSignup,
                oauthData: params,
                window,
              });
            }
          } else {
            dispatch({
              messages: [
                {
                  msg:
                    'OAuth redirect has occurred but no query or hash parameters were found.',
                },
              ],
              type: types.OauthFailure,
            });
          }
        }
      } catch (error) {
        // Ignore DOMException: Blocked a frame with origin from accessing a cross-origin frame.
        // A hack to get around same-origin security policy errors in Internet Explorer.
      }
    }, 500);
  });
}

/**
 * Get a token from an oauth provider
 * @param {Object} oauthData
 * @param {Object} config
 * @param {Object} window
 * @param {number} interval
 * @param {any} dispatch
 * @param {boolean} isSignup - True if the user is signing<br>
 * up for a new account. False if they are logging in.
 */
function exchangeCodeForToken({
  oauthData,
  config,
  window,
  interval,
  dispatch,
  isSignup,
}: any) {
  return new Promise(async resolve => {
    const data = Object.assign({}, oauthData, config);

    try {
      const response = await fetch(config.url, {
        body: JSON.stringify(data),
        credentials: 'same-origin',
        headers: { 'Content-Type': 'application/json' },
        method: 'post',
      });
      const json = await response.json();
      if (response.ok) {
        resolve({
          config,
          dispatch,
          interval,
          isSignup,
          token: json.token,
          user: json.user,
          window,
        });
      }
      EventEmitter.emit('signupError');
      dispatch({
        messages: [json],
        type: types.OauthFailure,
      });
      closePopup({ window, interval });
    } catch (error) {
      EventEmitter.emit('signupError');
      dispatch({
        error,
        type: types.GenericError,
      });
    }
  });
}

/**
 * Sign in using oauth
 * @param {string} token - An authentication token
 * @param {Object} user - A User object
 * @param {Object} window
 * @param {number} interval
 * @param {any} dispatch
 * @param {Object} config
 * @param {boolean} isSignup - True if the user is signing<br>
 * up for a new account. False if they are logging in.
 */
function signIn({
  token,
  user,
  window,
  interval,
  dispatch,
  config,
  isSignup,
}: any) {
  return new Promise(resolve => {
    const type = isSignup ? types.OauthSignupSuccess : types.OauthLoginSuccess;
    dispatch({
      signupPath: config.signupPath,
      entrancePath: config.entrancePath,
      token,
      type,
      user,
    });
    if (config.storeSurvey) {
      // Save their filled up survey values to DB if any
      dispatch(saveSurveyValues(token));
    } else {
      dispatch(fetchSearchSurvey(token));
      dispatch(fetchSignupSurvey(token));
    }
    cookie.set('token', token, {
      expires: TOKEN_EXPIRATION_DAYS,
    });
    localStore.setItem('token', token);
    dispatch(updateProfile({ lastWebLogin: moment() }, token, false, true));
    track.trackLastWebLogin(moment().format('MM/DD/YY hh:mm A'));
    // Get redux store values and execute signup callback if any
    const store = getOrCreateStore();
    const dialogStore = store.getState().dialogs;
    if (dialogStore && dialogStore.signupCallback) {
      dialogStore.signupCallback();
    }
    if (isSignup) {
      // Send email and phone authentication emails
      if (!user.emailVerified) {
        dispatch(sendVerification('email', token));
      }
      if (user.phoneNumber && !user.phoneNumberVerified) {
        dispatch(sendVerification('phone', token));
      }
      track.register(
        user.firstName,
        user.lastName,
        user.email,
        user.phoneNumber,
        config.authorizationUrl === faceBookAuthorizationUrl
          ? 'facebook'
          : 'google',
      );
    } else {
      track.login(
        user.firstName,
        user.lastName,
        user.email,
        user.phoneNumber,
        config.authorizationUrl === faceBookAuthorizationUrl
          ? 'facebook'
          : 'google',
      );
    }
    // Hide quick start guide, if user has already completed
    if (user.onboarding.quickStartComplete) {
      dispatch(disableQuickStartGuide());
    }
    // Open phoneNumber prompt if dashboardTour is already completed and phoneNumber is null
    // This logic works for oAuth login and Register
    if (user.onboarding.dashboardTourComplete && !user.phoneNumber) {
      dispatch(togglePhoneNumberOauthModal(true));
    }
    if (config.postAuthPath) {
      Router.push(config.postAuthPath);
    }
    // Open welcome Survey dialog only if no postAuthPath and user didnt came from survey flow
    if (!config.postAuthPath && !config.storeSurvey) {
      dispatch({ type: types.OpenWelcomeSurveyDialog });
    }
    resolve({ window, interval });
  });
}

/**
 * Sign in with Facebook
 * @param {boolean} isSignup - True if the user is signing<br>
 * up for a new account. False if they are logging in.
 * @param {string} postAuthPath - The page where a user should go after auth
 * @param {string} signupPath - The path from which a user signed up
 * @param {string} utmCampaign - The UTM campaign that a user came in with
 * @param {string} utmMedium - The UTM medium that a user came in with
 * @param {string} utmSource - The UTM source that a user came in with
 * @param {string} utmTerm - The UTM term that a user came in with
 * @param {string} entrancePath - The path where a user first entered the site
 */
export function facebookLogin(
  isSignup: boolean,
  postAuthPath?: string,
  signupPath?: string,
  utmCampaign?: string,
  utmMedium?: string,
  utmSource?: string,
  utmTerm?: string,
  entrancePath?: string,
  storeSurvey?: boolean,
) {
  const facebookConfig: ConfigTypes = {
    authorizationUrl: faceBookAuthorizationUrl,
    clientId: '1908869376024982',
    height: 400,
    postAuthPath,
    redirectUri: `${host}/auth/facebook/callback`,
    scope: 'email',
    signupPath,
    url: `${apiHost}/v1/auth/facebook`,
    utmCampaign,
    utmMedium,
    utmSource,
    utmTerm,
    width: 580,
    entrancePath,
    storeSurvey,
  };

  return (dispatch: Dispatch<any>) => {
    oauth2(facebookConfig, dispatch, isSignup)
      .then(openPopup)
      .then(pollPopup)
      .then(exchangeCodeForToken)
      .then(signIn)
      .then(closePopup);
  };
}

/**
 * Sign in with Google
 * @param {boolean} isSignup - True if the user is signing<br>
 * up for a new account. False if they are logging in.
 * @param {string} postAuthPath - The page where a user should go after auth
 * @param {string} signupPath - The path from which a user signed up
 * @param {string} utmCampaign - The UTM campaign that a user came in with
 * @param {string} utmMedium - The UTM medium that a user came in with
 * @param {string} utmSource - The UTM source that a user came in with
 * @param {string} utmTerm - The UTM term that a user came in with
 * @param {string} entrancePath - The path where a user first entered the site
 */
export function googleLogin(
  isSignup: boolean,
  postAuthPath?: string,
  signupPath?: string,
  utmCampaign?: string,
  utmMedium?: string,
  utmSource?: string,
  utmTerm?: string,
  entrancePath?: string,
  storeSurvey?: boolean,
) {
  const googleConfig: ConfigTypes = {
    authorizationUrl: googleAuthorizationUrl,
    clientId:
      '478729809840-7q3qt7fpmcklm8mikv4p392cp691rdcl.apps.googleusercontent.com',
    height: 633,
    postAuthPath,
    redirectUri: `${host}/auth/google/callback`,
    scope: 'openid profile email',
    signupPath,
    url: `${apiHost}/v1/auth/google`,
    utmCampaign,
    utmMedium,
    utmSource,
    utmTerm,
    width: 452,
    entrancePath,
    storeSurvey,
  };

  return (dispatch: Dispatch<any>) => {
    oauth2(googleConfig, dispatch, isSignup)
      .then(openPopup)
      .then(pollPopup)
      .then(exchangeCodeForToken)
      .then(signIn)
      .then(closePopup)
      .catch((error: any) => {
        EventEmitter.emit('signupError');
        dispatch({
          error,
          type: types.GenericError,
        });
      });
  };
}

/**
 * Link this oauth provider to a user's account
 * @param {string} provider - The oauth provider being used
 * @param {boolean} isSignup - True if the user is signing<br>
 * up for a new account. False if they are logging in.
 */
export function link(provider: string, isSignup: boolean) {
  switch (provider) {
    case 'facebook':
      return facebookLogin(isSignup);
    case 'google':
      return googleLogin(isSignup);
    default:
      return {
        messages: [{ msg: 'Invalid OAuth Provider' }],
        type: types.LinkFailure,
      };
  }
}

/**
 * Unlink an oauth prover from a user account
 * @param {string} provider - The oauth provider being used
 * @param {string} token - An authentication token
 */
// TODO: Unlinking is not updating in the UI.
export function unlink(provider: string, token: string) {
  return (dispatch: Dispatch<any>) =>
    fetch(`/v1/unlink/${provider}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(async response => {
        const json = await response.json();
        if (response.ok) {
          dispatch({
            messages: [json],
            type: types.UnlinkSucces,
          });
        } else {
          dispatch({
            messages: [json],
            type: types.UnlinkFailure,
          });
        }
      })
      .catch(error => {
        dispatch({
          error,
          type: types.GenericError,
        });
      });
}

import * as React from 'react';
import { get } from 'lodash';
import { TextField } from '@material-ui/core';

interface Props {
  input: any;
  meta: any;
  [x: string]: any;
}

const TextFieldInput = (props: Props) => (
  <TextField
    error={
      props.meta.touched && props.meta.error && props.meta.error.length > 0
    }
    helperText={props.meta.touched && props.meta.error}
    InputProps={props.InputProps}
    onChange={(event: any, index: any, value: any) =>
      props.input.onChange(value)
    }
    value={get(props, 'input.value', '')}
    {...props.input}
    {...props}
  />
);

export default TextFieldInput;

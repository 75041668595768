import * as React from 'react';

export interface MessagesTypes {
  error: any[];
  info: any[];
  success: any[];
}

export interface Props {
  messages: any;
}

const Messages = (props: Props) => {
  if (!props.messages) {
    return null;
  }
  if (
    props.messages.success &&
    Object.keys(props.messages.success).length !== 0
  ) {
    return (
      <div role="alert" className="alert text-success">
        {props.messages.success.map((message: any, index: number) => (
          <div key={index}>{message.msg}</div>
        ))}
      </div>
    );
  }
  if (props.messages.error && Object.keys(props.messages.error).length !== 0) {
    return (
      <div role="alert" className="alert text-danger">
        {props.messages.error.map((message: any, index: number) => (
          <div key={index}>{message.msg}</div>
        ))}
      </div>
    );
  }
  if (props.messages.info && Object.keys(props.messages.info).length !== 0) {
    return (
      <div role="alert" className="alert text-info">
        {props.messages.info.map((message: any, index: number) => (
          <div key={index}>{message.msg}</div>
        ))}
      </div>
    );
  }
  return null;
};

export default Messages;

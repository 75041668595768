import { parsePhoneNumber } from './helpers';
import { email } from '@sideway/address';

const maxLength = (max: number) => (value: string) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

const minLength = (min: number) => (value: any) =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;

const zipCodeValidation = (min: number) => (value: string) => {
  return (
    value &&
    (!/^[0-9]+$/.test(value)
      ? `Must only include numbers`
      : value.length != min && `Must be ${min} digits`)
  );
};

const validations = {
  required: (value: any) =>
    value && `${value}`.trim() ? undefined : 'Required',
  maxLength250: maxLength(250),
  minLength8: minLength(8),
  number: (value: any) =>
    value && Number.isNaN(Number(value)) ? 'Must be a number' : undefined,
  minValue: (min: number) => (value: number) =>
    value && value < min ? `Must be at least ${min}` : undefined,
  email: (value: string) =>
    value && !email.isValid(value) ? 'Invalid email address' : undefined,
  phoneNumber: (value: any) => {
    const parsedNumber = parsePhoneNumber(value);
    return parsedNumber && parsedNumber.length < 10
      ? 'Invalid phone number, must be 10 digits'
      : undefined;
  },
  zipCode: zipCodeValidation(5),
};

export default validations;

export const composeValidators = (...validators: any) => (value: any) =>
  validators.reduce(
    (error: any, validator: any) => error || validator(value),
    undefined,
  );
